import React, { useState, useEffect } from 'react';
import './App.css';
import WordDisplay from './WordDisplay';
import Controls from './Controls';

function App() {
  const [currentWord, setCurrentWord] = useState('');
  const [gameStarted, setGameStarted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hint, setHint] = useState('');
  const [showStartButton, setShowStartButton] = useState(true);
  const [showStopButton, setShowStopButton] = useState(true);
  const [showWordAndHint, setShowWordAndHint] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [timeTaken, setTimeTaken] = useState('');

  const dictionary = {
    eat: 'To put food into the mouth, chew it, and swallow it.',
    peel: 'To remove the outer covering of something, like a fruit or vegetable.',
    // Add more words and definitions here...
  };

  const generateRandomWord = () => {
    const wordPool = Object.keys(dictionary);
    const randomIndex = Math.floor(Math.random() * wordPool.length);
    return wordPool[randomIndex];
  };

  const startGame = () => {
    setShowStartButton(false);
    setLoading(true);
    setShowStopButton(true);
    setGameStarted(true);
    setStartTime(new Date());
    const word = generateRandomWord();
    setCurrentWord(word);
    setHint(dictionary[word] || 'No hint available.');
    setTimeout(() => {
      setShowWordAndHint(true);
    }, 5 * 60 * 1000); // 5 minutes in milliseconds
  };

  const stopGame = () => {
    setLoading(false);
    setShowStopButton(false);
    setGameStarted(true);
    setShowWordAndHint(true);
    const word = generateRandomWord();
    setCurrentWord(word);
    setHint(dictionary[word] || 'No hint available.');

    const endTime = new Date();
    const timeTakenInSeconds = Math.floor((endTime - startTime) / 1000);
    if (timeTakenInSeconds < 60) {
      setTimeTaken(`${timeTakenInSeconds} seconds`);
    } else {
      const minutes = Math.floor(timeTakenInSeconds / 60);
      setTimeTaken(`${minutes} minute${minutes > 1 ? 's' : ''}`);
    }
  };

  useEffect(() => {
    if (showWordAndHint && !gameStarted) {
      setShowWordAndHint(true);
    }
  }, [showWordAndHint, gameStarted]);

  return (
    <div className="App">
      <header className="header">
        <h1 className="title">Guess The Word</h1>
      </header>
      <div className="content">
        <WordDisplay
          word={currentWord}
          hint={hint}
          gameStarted={gameStarted}
          loading={loading}
          showWordAndHint={showWordAndHint}
          timeTaken={timeTaken}
        />
        <Controls
          startGame={startGame}
          stopGame={stopGame}
          gameStarted={gameStarted}
          showStartButton={showStartButton}
          showStopButton={showStopButton}
          loading={loading}
          timeTaken={timeTaken}
        />
      </div>
      <footer className="footer">
        <p>made by <a href="https://x.com/jasimvk" target="_blank" rel="noopener noreferrer">Jasim</a></p>
      </footer>
    </div>
  );
}

export default App;
