import React from 'react';
import './Controls.css';

function Controls({ startGame, stopGame, gameStarted, showStartButton, showStopButton, loading, timeTaken }) {
  const handleShare = () => {
    const textToCopy = `Yay! I found the word in ${timeTaken} minutes. What about you? https://guesstheword.online`;
    navigator.clipboard.writeText(textToCopy).then(() => {
      alert('Text copied to clipboard!');
    }).catch((err) => {
      console.error('Failed to copy text: ', err);
    });
  };

  return (
    <div className="controls">
      {loading ? (
        <p className="loading-text">Finding the word...</p>
      ) : (
        !gameStarted && showStartButton && (
          <button onClick={startGame} className="start-button">
            Look for a word from the dictionary
          </button>
        )
      )}
      {gameStarted && showStopButton && (
        <button onClick={stopGame} className="stop-button">
          Stop
        </button>
      )}
      {gameStarted && !loading && (
        <button onClick={handleShare} className="share-button">
          Share
        </button>
      )}
    </div>
  );
}

export default Controls;
