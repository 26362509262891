import React, { useState, useEffect } from 'react';
import './WordDisplay.css';

const WordDisplay = ({ word, hint, gameStarted, loading, showWordAndHint, timeTaken }) => {
  const [userGuess, setUserGuess] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [timeLeft, setTimeLeft] = useState(5 * 60); // 5 minutes in seconds



  useEffect(() => {
    let timer;
    if (gameStarted && !loading && !showWordAndHint) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [gameStarted, loading, showWordAndHint]);

  useEffect(() => {
    if (timeLeft === 0) {
      setSubmitted(true); // Automatically submit after timeout
    }
  }, [timeLeft]);

  const handleInputChange = (event) => {
    setUserGuess(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const guess = userGuess.toLowerCase().trim();
    const correctWord = word.toLowerCase().trim();
    if (guess === correctWord) {
      setSubmitted(true);

    } else {
      alert('Sorry, your guess is incorrect. Try again!');
      setUserGuess('');
    }
  };

  const handleNewGame = () => {
    //navigate to the main page
    window.location.reload();

  };

  const generateMaskedWord = (word) => {
    if (!gameStarted || loading || !showWordAndHint) {
      return '';
    } else if (word && word.length > 2) {
      return `${word.charAt(0)}${'_ '.repeat(word.length - 2)}${word.charAt(word.length - 1)}`;
    } else if (word) {
      return word.replace(/./g, '_ ').trim();
    }
    return '';
  };

  return (
    <div>
      {submitted ? (
        <>
          <h1 className="success-message">Yay! You found the word in {timeTaken} minutes.</h1>
          <h2 className="revealed-word">{word}</h2>
          <p className="definition">Definition: {hint}</p>

          <button onClick={handleNewGame} className="new-game-button">New Game</button>
        </>
      ) : showWordAndHint && (
        <>
          <h2 className="masked-word">{generateMaskedWord(word)}</h2>
          <p className="definition">Definition: {hint}</p>
          {/* <p className="time-left">Time Left: {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 })} minutes</p> */}
          <form onSubmit={handleSubmit} className="guess-form">
            <label className="guess-label">
              Your Guess:
              <input 
                type="text" 
                value={userGuess} 
                onChange={handleInputChange} 
                disabled={timeLeft === 0}
                className="guess-input"
              />
            </label>
            <button type="submit" disabled={timeLeft === 0} className="submit-button">Submit Guess</button>
          </form>
        </>
      )}
    </div>
  );
};

export default WordDisplay;
